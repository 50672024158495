import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import LockIcon from "@material-ui/icons/Lock";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

//styles

import styles from "assets/jss/nextjs-material-kit/components/badgeStyle.js";
import customStyles from "assets/jss/custom/customStyles.js";

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
  headLine: {
    opacity: 0.5,
    fontSize: '0.75rem',
    margin: '10px 0',
    textTransform: 'uppercase',
    fontWeight: 600,
    display: 'block'
  },
  subLine: {
    marginTop: '5px',
    marginBottom: '10px',
    fontWeight: 600,
    lineHeight: '1.4em',
    fontSize: '18px'
  }
});

export default function USPBlock() {
  const classes = useStyles();

  const iconStyle = { fontSize: 60 };
  const iconClasses = classNames(classes.hasTextShadow, classes.hasPadding);

  const trendingIcon = (
    <TrendingUpIcon
      style={iconStyle}
      className={iconClasses}
      alt="Sugardaddy Community"
    />
  );

  const userIcon = (
    <VerifiedUserIcon
      style={iconStyle}
      className={iconClasses}
      alt="Sugardaddy Plattform"
    />
  );

  const lockIcon = (
    <LockIcon
      style={iconStyle}
      className={iconClasses}
      alt="Seriöse Sugardaddy Plattform"
    />
  );

  const usps = [
    {
      headline: "Wachsende Community",
      subline: "Viele Registrierungen täglich und viele aktive Mitglieder",
      icon: trendingIcon,
    },
    {
      headline: "Kompetent & Qualifiziert",
      subline: "Die wohl beste Plattform für Sugarbabes & Sugardaddys",
      icon: userIcon,
    },
    {
      headline: "Grösstmögliche Sicherheit",
      subline: "Serverstandort und Datenschutz in Deutschland",
      icon: lockIcon,
    },
  ];

  return (
    <div className={classes.rowOdd}>
      <div className={classes.container}>
        <GridContainer
          alignItems="stretch"
          justify="space-between"
          direction="row"
        >
          {usps.map((usp) => (
            <GridItem xs={12} sm={4}>
              <GridContainer alignItems="center">
                <GridItem xs={3}>{usp.icon}</GridItem>
                <GridItem xs={9}>
                  <span className={classNames(classes.headLine)}>
                    {usp.headline}
                  </span>
                  <span className={classNames(classes.subLine)}>
                    {usp.subline}
                  </span>
                </GridItem>
              </GridContainer>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
}
