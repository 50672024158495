import React, { useState, useRef, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useRouter } from "next/router";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import Head from "next/head";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Hidden from "@material-ui/core/Hidden";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import customStyles from "assets/jss/custom/customStyles.js";

// Sections for this page
import TeamSection from "pages-sections/LandingPage-Sections/TeamSection.js";
//components

import TypeSelect from "components/Content/TypeSelect";
import CTABlock from "components/Content/CTABlock";

import USPBlock from "components/Content/USPBlock.js";

//header image

import headerImage1 from "assets/img/header/sugardaddy-sugarbabe.jpg";
import headerImage2 from "assets/img/header/sugardaddy-finden.jpg";
import headerImage3 from "assets/img/header/sugarbabe-finden.jpg";

import mobileHeaderImage1 from "assets/img/header/sugardaddy-sugarbabe-mobile.jpg";
import mobileHeaderImage2 from "assets/img/header/sugardaddy-finden-mobile.jpg";
import mobileHeaderImage3 from "assets/img/header/sugarbabe-finden-mobile.jpg";

import headerImageAlternate from "assets/img/header/sugar-daddy-portal.jpg";
import mobileHeaderImageAlternate from "assets/img/header/sugar-daddy-portal-mobile.jpg";

import sneakSearchProfiles from "assets/img/sneakSearchProfiles.jpg";

import sneakExplore from "assets/img/sneakExplore.svg";
import sneakMessenger from "assets/img/sneakMessenger.svg";
import sneakEvents from "assets/img/sneakEvents.svg";
import { Avatar, Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Parser from "rss-parser";
import { primaryColor } from "assets/jss/nextjs-material-kit";
import { TrustElements } from "components/Content/TrustElements";

//Styles

const headerImages = [
	{
		desktop: headerImage1.src,
		mobile: mobileHeaderImage1.src,
		alt: "Sugardaddy Sugarbabe",
	},
	{
		desktop: headerImage2.src,
		mobile: mobileHeaderImage2.src,
		alt: "Sugardaddy finden",
	},
	{
		desktop: headerImage3.src,
		mobile: mobileHeaderImage3.src,
		alt: "Sugarbabe finden",
	},
];

const headerImageAlternateData = {
	desktop: headerImageAlternate.src,
	mobile: mobileHeaderImageAlternate.src,
	alt: 'Sugar Daddy Portal getasugar'
}

const headerImage =
	headerImages[Math.floor(Math.random() * Math.floor(headerImages.length))];

//const headerImage = headerImages[2]

const dashboardRoutes = [];

const useStyles = makeStyles({
	...styles,
	...customStyles,
	rowImage: {
		backgroundImage: `url(${headerImage2.src})`,
		backgroundSize: "cover",
		backgroundPosition: "top center",
	}
});

export async function getStaticProps() {
	const parser: Parser = new Parser({
		headers: {
			Authorization: "Basic c2R1c2VyOnNkMjAyMA==",
		},
	});
	const tagCities = [
		"Berlin",
		"München",
		"Hamburg",
		"Wien",
		"Frankfurt am Main",
		"Zürich",
		"Düsseldorf",
		"Innsbruck",
		"Köln",
		"Stuttgart",
		"Hannover",
		"Graz",
		"Nürnberg",
		"Salzburg",
		"Dresden",
		"Basel",
		"Leipzig",
		"Karlsruhe",
		"Genf",
		"Bremen",
		"Bern",
		"Dortmund",
		"Linz",
	];

	const feed = await parser.parseURL("https://www.getasugar.de/ratgeber/feed");
	const shuffledArray = feed.items.sort((a, b) => 0.5 - Math.random());

	const blogTeaser = shuffledArray.slice(0, 6).map((item) => {
		let urlMatch = item.content.match(/<img.*src="(.*?)"/i);

		return {
			title: item.title,
			imageUrl: urlMatch && urlMatch[1],
			teaser: item.contentSnippet,
			url: item.link,
		};
	});

	return {
		props: {
			blogTeaser,
			tagCities,
		},
		revalidate: 300,
	};
}

export default function LandingPage(props) {
	const classes = useStyles();
	const router = useRouter();
	const { tagCities, blogTeaser, ...rest } = props;
	const [type, setType] = useState<"0" | "1">("0");
	const [isPromo, setIsPromo] = useState(false)
	const [promo, setPromo] = useState("")
	const video = useRef<HTMLVideoElement>();
	const playButton = useRef<HTMLDivElement>();

	const theme = useTheme();
  const isDownXS = useMediaQuery(theme.breakpoints.down("xs"));

	useEffect(() => {
		setIsPromo(typeof window !== "undefined" &&
		(localStorage.getItem("currentPromo") === "sforum" || localStorage.getItem("currentPromo") === "smaschke"))
		setPromo(typeof window !== "undefined" &&
		localStorage.getItem("currentPromo"))
	}, [])

	let typeSelect = (
		<TypeSelect setType={setType} type={type} variant="highlighted" />
	);

	const purpleCTAContainer = (
		<div className={classNames(classes.rowOdd, classes.isWhite)}>
			<CTABlock />
		</div>
	);

	const headerSignup = (
		<div className={classes.container}>
			<Grid container alignItems="center" justify="space-between">
				<Grid item xs={12} sm={8} md={5} style={{ padding: 15, marginTop: 35 }}>
					<div className={classes.signUpBox}>
						<h1 className={classes.title}>
							Erfolgreiche Sugardaddys
							<br />
							treffen attraktive Damen
						</h1>

						<div
							className={classNames(classes.isBlack, classes.hasMarginTop10)}
						>
							{typeSelect}
						</div>
						<Button
							color="primary"
							fullWidth
							onClick={() => router.push("/register?type=" + type)}
						>
							jetzt kostenlos flirten
						</Button>

						<Hidden xsDown>
							<p
								className={classNames(
									classes.hasTextCentered,
									classes.hasMarginTop10
								)}
							>
								<small>
									Schon bei getasugar?{" "}
									<a
										href={process.env.NEXT_PUBLIC_FRONTEND_BASEURL + "/login"}
										className={classNames(classes.isPrimary, classes.isBold)}
									>
										» Login
									</a>
								</small>
							</p>
						</Hidden>
						<div>
							<ul className={classes.checkmarkList}>
								<li>
									<strong>Anonym flirten </strong>
									<Hidden xsDown> - individuelle Namenswahl möglich</Hidden>
								</li>
								<li>
									<strong>Diskret & sicher</strong>
									<Hidden xsDown> - Verschlüsselte Kommunikation</Hidden>
								</li>
								<li>
									<strong>Verifizierte Mitglieder</strong>
									<Hidden xsDown>- Geprüfte Profile und Bilder</Hidden>
								</li>
							</ul>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={7}></Grid>
			</Grid>
		</div>
	);

	const onVideoClick = (event) => {
		event.preventDefault();
		if (video.current?.paused) {
			video.current?.play();
			if (playButton.current) {
				playButton.current.style.display = "none";
			}
		} else {
			video.current?.pause();
			if (playButton.current) {
				playButton.current.style.display = "block";
			}
		}
	};

	return (
		<div>
			<Head>
				<title>
					Sugardaddy & Sugarbabe finden auf getasugar | Sugar Dating
				</title>
				<meta
					name="description"
					content="Die Dating Plattform für Sugar Daddys & Sugar Babes: Attraktive Frauen auf der Suche nach erfolgreichen Gentlemen | Kostenlose Anmeldung | Sitz in DE"
				/>
			</Head>
			<Header
				color="primary"
				routes={dashboardRoutes}
				rightLinks={<HeaderLinks adaptiveFold={true} />}
				fixed
				isPromo={isPromo}
				promo={promo}
				{...rest}
			/>
			<Parallax
				responsive
				image={headerImageAlternateData.desktop}
				mobileImage={headerImageAlternateData.mobile}
				alt={headerImageAlternateData.alt}
				style={{ paddingBottom: 20 }}
				basePaddingTop={isPromo? isDownXS? 3.6 : 1.8 : 0}
			>
				<Hidden xsDown>{headerSignup}</Hidden>
			</Parallax>

			<div className={classes.main}>
				<Hidden smUp>{headerSignup}</Hidden>
				<USPBlock />
				<TrustElements />
				<div style={{backgroundColor: '#f3f2f2'}}>
					<div
						className={classNames(classes.container, classes.hasMarginTop10)}
					>
						<Grid container alignItems="flex-start">
							<Grid item xs={12}>
								<h2 className={classes.h2}>Was ist getasugar?</h2>
								<p className={classes.isTeaser}>
									Besondere Begegnungen, die glücklich machen ...
								</p>
							</Grid>
							<Grid item xs={12} sm={5} md={5} lg={6}>
								<p style={{ paddingTop: "1em" }}>
									Auf getasugar.de findest du spannende und niveauvolle
									Begegnungen, die auf absoluter Ehrlichkeit beruhen.
									Erfolgreiche und finanziell unabhängige Gentlemen (Sugardaddy)
									treffen hier auf attraktive, junge Damen (Sugarbabe). Die
									Ansprüche und Bedürfnisse beider Partner sind von Anfang an
									klar definiert und transparent.
								</p>

								<p style={{ paddingTop: "1em" }}>
									Anders als bei traditionellen Beziehungen stehen bei Sugar
									Beziehungen nützliche und erfüllende Aspekte zwischen zwei
									Menschen, aber auch die Freiheit des Einzelnen im Vordergrund.
								</p>
							</Grid>
							<Grid item xs={12} sm={7} md={7} lg={6}>
								<div
									style={{
										position: "relative",
										textAlign: "right",
										cursor: "pointer",
									}}
								>
									<video
										src="/getasugar-spot.mp4#t=0.5"
										ref={video}
										preload="metadata"
										playsInline
										style={{
											width: "100%",
											maxWidth: "480px",
											position: "relative",
											paddingBottom: "1em",
											paddingTop: "1em",
										}}
										onClick={onVideoClick}
									>
										<source src="/getasugar-spot.mp4#t=0.5" type="video/mp4" />
									</video>
									<div
										ref={playButton}
										onClick={onVideoClick}
										style={{
											position: "absolute",
											margin: "auto",
											top: "calc(50% - 2rem)",
											right: 0,
											fontSize: "2rem",
											textAlign: "center",
											width: "100%",
											maxWidth: "480px",
											color: "#fff",
										}}
									>
										<PlayCircleOutlineIcon
											style={{
												width: "2em",
												height: "2em",
											}}
										/>
									</div>
								</div>
							</Grid>

							<Grid item xs={12}>
								<p>
									Damen profitieren vom Lifestyle und Erfolg der Sugardaddys.
									Mit Stil und Lebenserfahrung heben sich diese klar von den
									männlichen Kontakten klassischer Dating Portale ab.
									Sugardaddys sind Männer, die es verstehen Frauen für sich und
									ihr Leben zu begeistern. Für ihr Date sind sie oftmals
									Türöffner in bisher verschlossene Welten, Mentor und
									spannendes Abenteuer zugleich.
								</p>

								<p style={{ paddingTop: "1em" }}>
									Für die Herren liegt der Reiz einer Sugar Beziehung an der
									Leichtigkeit und Experimentierfreude der zumeist jüngeren
									Partnerin. Er genießt die weibliche Begleitung und das
									Zusammensein mit einer attraktiven Dame während er ihr die
									schönen Seiten des Lebens zeigt.
								</p>
							</Grid>
						</Grid>
					</div>
				</div>

				{purpleCTAContainer}
				<div className={classNames(classes.container, classes.hasMarginTop10)}>
					<Grid container alignItems="flex-start">
						<Grid item xs={12} className={classes.hasTextCentered}>
							<h2 className={classes.h2}>Warum du getasugar lieben wirst</h2>
							<p
								className={classNames(
									classes.isTeaser,
									classes.isTeaserFullwidth
								)}
							>
								Entdecke mit unserer Community die angenehmen und inspirierenden
								Seiten des Lebens: Unsere Mitglieder suchen Dates für besondere
								Momente und Verbindungen
							</p>

							<img
								src={sneakSearchProfiles.src}
								alt="Sugardaddy App"
								className={classes.contentImage}
							/>

							<p>
								Deine Registrierung ist kostenfrei. Auch ohne kostenpflichtige
								VIP Mitgliedschaft kannst du ein ausführliches Dating Profil
								erstellen, andere Mitglieder durch virtuelle Küsse auf dich
								aufmerksam machen und Nachrichten beantworten. Möchtest du
								unsere Plattform anonym kennenlernen, ist dies durch freie
								Namenswahl möglich.
							</p>
						</Grid>
					</Grid>
				</div>
				<div className={classes.rowGrey}>
					<div
						className={classNames(classes.container, classes.hasMarginTop10)}
					>
						<Grid container alignItems="center" justifyContent="center">
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classNames(
									classes.hasTextCentered,
									classes.switchOrderMobilePos2
								)}
							>
								<img src={sneakExplore.src} alt="Sugardaddys" height="320" />
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classes.switchOrderMobilePos1}
							>
								<h3>Lifestyle Dates, die zu dir passen</h3>
								<p>
									Stilvolle Gentlemen nutzen getasugar.de, um auf attraktive
									Damen zu treffen. Entdecke spannende Profile in deiner Nähe.
								</p>
							</Grid>
						</Grid>
					</div>
				</div>

				<div>
					<div
						className={classNames(classes.container, classes.hasMarginTop10)}
					>
						<Grid container alignItems="center" justifyContent="center">
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classes.switchOrderMobilePos1}
							>
								<h3>Gemeinsame Erlebnisse, die verbinden</h3>

								<p>
									Außergewöhnliche Events mit inspirierenden Persönlichkeiten
									erleben. Unsere Event Funktion bringt euch zusammen.
								</p>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classNames(
									classes.hasTextCentered,
									classes.switchOrderMobilePos2
								)}
							>
								<img
									src={sneakEvents.src}
									alt="Sugardaddy treffen"
									height="320"
								/>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className={classes.rowGrey}>
					<div
						className={classNames(classes.container, classes.hasMarginTop10)}
					>
						<Grid container alignItems="center" justifyContent="center">
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classNames(
									classes.hasTextCentered,
									classes.switchOrderMobilePos2
								)}
							>
								<img
									src={sneakMessenger.src}
									alt="Sugardaddys kennenlernen"
									height="320"
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={5}
								className={classes.switchOrderMobilePos1}
							>
								<h3>Entspanntes Kennenlernen</h3>

								<p>
									Ein komfortabler Chat mit privatem Bild-Upload gibt dir die
									Möglichkeit, andere Mitglieder besser kennenzulernen und
									Treffen zu planen.
								</p>
							</Grid>
						</Grid>
					</div>
				</div>

				{purpleCTAContainer}

				<div className={classNames(classes.container, classes.hasMarginTop10)}>
					<TeamSection />
				</div>

				{purpleCTAContainer}

				<div className={classNames(classes.container, classes.hasMarginTop10)}>
					<Grid container alignItems="stretch">
						<Grid item xs={12} className={classes.hasTextCentered}>
							<h2 className={classes.h2}>Sugar Dating in deiner Region</h2>
							<p
								className={classNames(
									classes.isTeaser,
									classes.isTeaserFullwidth
								)}
							>
								In vielen Städten in Deutschland, Österreich und der Schweiz
								warten aufregende Sugar Dates auf dich
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							className={classNames(
								classes.hasTextCentered,
								classes.hasMarginTop10
							)}
						>
							{tagCities.map((city) => (
								<Chip
									label={city}
									color="primary"
									className={classes.primaryChip}
									onClick={() => router.push("/register")}
								/>
							))}
						</Grid>
					</Grid>
				</div>

				<div className={classes.rowGrey}>
					<div
						className={classNames(classes.container, classes.hasMarginTop10)}
					>
						<Grid container alignItems="flex-start">
							<Grid item xs={12} className={classes.hasTextCentered}>
								<h2 className={classes.h2}>
									Ratgeber: Sugardaddy Sugarbabe Beziehung
								</h2>
							</Grid>
							<Grid
								item
								xs={12}
								className={classNames(
									classes.hasTextCentered,
									classes.hasMarginTop10
								)}
							>
								<Grid container spacing={4}>
									{blogTeaser.map((teaser) => (
										<Grid item xs={12} sm={4}>
											<Paper
												style={{ height: "100%", padding: "0.5em" }}
												elevation={0}
											>
												<h3
													style={{
														lineHeight: "1em",
														padding: "0 1em",
														marginBottom: "1em",
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
														overflow: "hidden",
													}}
												>
													{teaser.title}
												</h3>
												<img
													src={teaser.imageUrl}
													width="100%"
													alt={teaser.title}
												/>
												<div
													style={{
														marginTop: "1em",
														padding: "0.5em",
														textAlign: "left",
														height: "7.5em",
														position: "relative",
														overflow: "hidden",
													}}
												>
													<div
														style={{
															position: "absolute",
															zIndex: "99",
															bottom: "0",
															left: "0",
															paddingTop: "3.5em",
															width: "100%",
															background:
																"url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAA8CAYAAABfESsNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAOtJREFUeNrsldsOAiEMROkKyIv//7dWa6xB2sKQaOKDJJvNhsOktx2ImWsCFt1BhEtZ4OfD3fslpN8CXlAwD+DVOyhQmYSm8AM8oclUFMxfV+xL9JZ1RcHyyRhJFcfWmRBUkYZNc7BXXMbYOrXkKNJ21s2blnGKVJGRgjcUzAFkCt5QcJU1e4pTsASbfRNSpOiCeZXITDH8Z6KBMHXsrSRULA5ohkPAc+A3GgJvdwZ2Crgzdacz5AA81rGiYJ702XiPiSnqNS9MIByz0YNIb4UoGbg85MU49UiBDgSUaxi6h48Erj/4q+BNgAEALtBQzxcBRLwAAAAASUVORK5CYII=')",
														}}
													></div>
													<p>{teaser.teaser}</p>
												</div>
												<div>
													<a
														href={teaser.url}
														target="_blank"
														className={classNames(
															classes.isPrimary,
															classes.isBold,
															classes.isUppercase
														)}
													>
														Weiterlesen &raquo;
													</a>
												</div>
											</Paper>
										</Grid>
									))}
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className={classes.container}>
					<Grid container alignItems="stretch">
						<Grid item xs={12} className={classes.hasTextCentered}>
							<h2 className={classes.h2}>
								Sugardaddys und Sugarbabes bei getasugar
							</h2>

							<p>
								Auf getasugar treffen echte Sugardaddys auf attraktive
								Sugarbabes. Dich erwarten spannende Flirts und prickelnde Chats
								mit realen Personen, die Niveau und einen gehobenen Lifestyle
								genauso schätzen wie du. Anders als auf vielen anderen Partner
								Portalen gehst du mit getasugar auf Nummer sicher: Unsere
								Community wird auf Servern in Deutschland gehostet. Unsere Büros
								befinden sich in Hamburg. Unser Mitglieder-Bereich wird nach
								strengem deutschen Datenschutz geschützt und SSL verschlüsselt.
								Die Anmeldung ist für Frauen und Männer kostenlos.
							</p>

							<h2 className={classes.h2}>Was ist ein Sugar Daddy Portal?</h2>

							<p>
								Sugar Daddy Portale sind Dating Websites speziell für spendable,
								erfolgreiche oder reiche Männer. Hier sind Geschäftsmänner,
								Unternehmer oder Privatiers auf der Suche nach Kontakten zu
								attraktiven, interessanten oder auch jüngeren Frauen. Frauen auf
								Sugar Daddy Portalen suchen hingegen nach Männern, die sich
								einen gewissen Luxus in ihrem Leben gönnen. An der Seite dieser
								Männer möchten sie an deren Lifestyle und Erfolg teilhaben.
								Diese Damen, sie werden Sugar Babes genannt, genießen schöne
								Abende in guten Restaurants und gemeinsame Aktivitäten, wie
								Reisen oder Ausflüge, mit ihren Sugar Daddys. Da Sugar Babes
								häufig junge Damen in zwischen 20 und 30 Jahre sind, schätzen
								sie die Erfahrung ihrer älteren, männlichen Sugar
								Bekanntschaften: Oft werden ihre Sugar Daddys für sie auch zum
								Mentor und Bezugsperson für wichtige Lebensentscheidungen.
							</p>

							<p>
								In der Regel sind Sugar Beziehungen mittel- oder langfristig
								ausgelegt: Sowohl der Mann als auch die Frau möchten bei dieser
								Form von Dating eine persönliche und emotionale Ebene mit dem
								Gegenüber. Einmaligkeiten und kurze Treffen stehen nicht im
								Vordergrund. Sugar Daddy und Sugar Babe möchten sich
								kennenlernen und mehr von einander erfahren. Es soll eine
								vertraute und gleichzeitig intime Ebene mit dem Sugar Partner
								geschaffen werden. Dies geschieht meist durch regelmäßige Dates
								und den langfristigen Kontakt. Nichtsdestotrotz gibt es auch
								zahlreiche Mitglieder oder Singles auf Sugar Daddy Portalen,
								welche auf der einen Seite nach unverbindlichen, einmaligen
								Treffen beziehungsweise ein Affäre suchen. Auf der anderen Seite
								gibt es zahlreiche Frauen und Männer, die mit dem Ziel einer
								dauerhaften Beziehung im traditionellen Sinn und für die große
								Liebe auf Dating Websites wie getasugar sind.
							</p>

							<h2 className={classes.h2}>
								Als Sugardaddy ein Sugarbabe finden
							</h2>

							<p>
								{" "}
								Möchte man als Mann eine interessante, schöne und möglicherweise
								auch jüngere Frau kennenlernen, bietet eine Sugardaddy Website
								wie getasugar eine großartige Möglichkeit für prickelnde
								Kontakte. Hier findet man zahlreiche Profile von attraktiven und
								spannenden Frauen. Alle sind auf der Suche nach einem
								erfolgreichen{" "}
								<a
									href="https://www.getasugar.de/ratgeber/was-ist-ein-sugardaddy/"
									target="_blank"
								>
									Sugardaddy
								</a>{" "}
								und Gentleman, der sie in seine Welt mitnimmt und an seinem
								Leben teilhaben lässt. Die Altersspanne der Sugarbabe Profile
								reicht dabei von jungen Frauen im Alter ab 18 Jahren über bis
								hin zu älteren und erfahrenen Ladys höheren Alters.
							</p>

							<p>
								Auf getasugar.de findet man ausschließlich echte Frauen mit
								Interesse an einer Sugar Beziehung. Und das zudem auch noch
								besonders seriös: Die freiwillige Möglichkeit sich mit Ausweis
								oder Foto zu identifizieren gibt zusätzliche Sicherheit beim
								gegenseitigen Kennenlernen Mit virtuellen Küssen – diese
								Funktion ist komplett kostenlos - können sowohl Sugarbabe und
								Sugardaddy auf sich aufmerksam machen und schauen, ob es zu eine
								Gegenreaktion kommt. Per Chat kann man anschließend mehr über
								einander erfahren. Die Chat Funktion ist sowohl - ähnlich einer
								Messanger App - mit dem Smartphone, aber auch mit einem Desktop
								Computer möglich. Der Austausch per Chat Nachrichten ist die
								perfekte Möglichkeit die Erwartungen des Flirt Partners vor
								einem Treffen zu erfahren und Fotos auszutauschen.
							</p>

							<p>
								Durch die regionale Eingrenzung der Suchergebnisse auf der
								Sugardating Plattform kann man gezielt Sugarbabes in der Nähe
								kennenlernen. Bei einer geplanten Geschäftsreise, beispielsweise
								nach Berlin, Frankfurt oder München, kann der Sugardaddy vorab
								Kontakt zu interessanten Frauen in diesen Städten aufnehmen.
								Abends, nach Feierabend, kann dann ein Kennenlernen bei einem
								ersten Date im Restaurant, in der Bar oder im Club erfolgen.
							</p>

							<h2 className={classes.h2}>Einen Sugardaddy kennenlernen</h2>

							<p>
								Es ist der Traum vieler Frauen, einen wohlhabenden,
								erfolgreichen Mann oder auch einen Sponsor für die schönen Dinge
								des Lebens kennenlernen zu können. Doch wo kann eine Frau genau
								diesen Typ Mann treffen, den die Gesellschaft gerne als
								Sugardaddy betitelt? In der Regel trifft man potentielle
								Sugardaddys dort, wo sich die Schönen und Reichen aufhalten. Die
								kann beispielsweise in Luxus Hotels, noblen Restaurants oder
								angesagten Bars sein.
							</p>

							<p>
								Eine andere Möglichkeit einen gut situierten Mann kennenzulernen
								sind Sugardating Seiten wie getasugar. Auf diesem Dating Portal
								treffen Frauen auf Geschäftsführer, Manager und Selbständige.
								Ein erstes Kennenlernen ist ganz einfach via Chat möglich.
								Ausweis und Foto Verifizierungen zeigen den Frauen und
								potentiellen Sugarbabes wie vertrauenswürdig ein Sugardaddy auf
								der Plattform ist. Auch Einkommen und Vermögen des Flirtpartners
								lassen sich auf der Seite einsehen, sofern es vom Gegenüber
								freiwillig angelegt wurde. In Punkten wie diesen unterscheidet
								sich getasugar deutlich von klassischen Single Börsen. Um
								beruflich erfolgreiche oder reiche Männer kennenzulernen ist
								getasugar für Single Frauen die erste Wahl.
							</p>

							<h2 className={classes.h2}>
								getasugar als Sugardaddy App nutzen
							</h2>

							<p>
								Online flirten macht Spaß – vor allem mit dem Smartphone. Mit
								dem Handy kann man direkt auf neue Nachrichten von interessanten
								Männern und Frauen antworten ohne sie warten zu lassen. Zudem
								kann man unterwegs im Bus oder der Bahn durch spannende Profile
								scrollen und Favoriten für ein potentielles Date setzen.
							</p>

							<p style={{ marginBottom: "1em" }}>
								Das Sugardating Portal getasugar ist so konzipiert, dass du
								unsere Website wie eine App benutzen kannst: Aktiviere, nachdem
								du dich eingeloggt hast, in den Einstellungen die Push
								Mitteilungen. So kannst du direkt über neue Nachrichten
								informiert werden und sofort reagieren. Um getasugar wie eine
								App öffnen zu können, füge die URL{" "}
								<a href="https://web.getasugar.de" target="_blank">
									https://web.getasugar.de
								</a>{" "}
								zu deinem Homescreen hinzu. Besuche dafür die Website. Beim
								iPhone nutzt du im Anschluss die Funktion „Teilen“ und wählst
								dann die Option „Zum Home-Bildschirm“ aus. Android Nutzer tippen
								auf die 3 Punkte und wählen im Anschluss „Zum Startbildschirm
								hinzufügen“ aus.
							</p>
						</Grid>
					</Grid>
				</div>
			</div>

			<Footer />
		</div>
	);
}
