import { Avatar, Box, Grid } from "@material-ui/core"
import MuiButton from "@material-ui/core/Button";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from "next/router";

import CheckIcon from "@material-ui/icons/Check";

import sugardaddyAdv from "assets/img/sugardaddy-plattform-vorteile.jpg";
import sugarbabeAdv from "assets/img/sugarbabe-plattform-vorteile.jpg";

import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import customStyles from "assets/jss/custom/customStyles.js";
import headerImage2 from "assets/img/header/sugardaddy-finden.jpg";
import { primaryColor } from "assets/jss/nextjs-material-kit";

const useStyles = makeStyles((theme)=>({
	...styles,
	...customStyles,
	rowImage: {
		backgroundImage: `url(${headerImage2.src})`,
		backgroundSize: "cover",
		backgroundPosition: "top center",
	},
	advantageList: {
		listStyle: "none",
		paddingInlineStart: 0,
    '& li': {
      lineHeight: 1.7,
      fontSize: '0.9em'
    }
	},
	advantageCheck: {
		color: primaryColor,
		verticalAlign: "middle",
	},
	advantageTitle: {
		fontWeight: "bold",
		fontSize: "1.1em",
	},
  gridStyle: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '2em',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  perkList: {
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
    }
  }
}));

export const TrustElements = () => {

  const classes = useStyles();
  const router = useRouter();

  return (
    <div className={classNames(classes.container)} style={{marginBottom: '1.8em'}}>
					<Grid container justifyContent="space-between">
						<Grid 
              className={classes.gridStyle}
							item 
							xs={12} 
							md={6}
						>
								<Avatar
									src={sugardaddyAdv.src}
									alt="Sugardaddy"
									style={{
										width: "7em",
										height: "7em",
										display: "inline-block",
									}}
								/>
								<Box className={classes.perkList}>
									<h2 className={classes.advantageTitle}>
										Warum Sugardaddys getasugar lieben:
									</h2>
									<ul className={classes.advantageList}>
										<li>
											<CheckIcon className={classes.advantageCheck} /> Sehr
											hoher Sugarbabe Anteil von 69%
										</li>
										<li>
											<CheckIcon className={classes.advantageCheck} /> Profile
											und Bilder sehen nur Mitglieder
										</li>
										<li>
											<CheckIcon className={classes.advantageCheck} /> Viele
											geprüfte und verifizierte Sugarbabes
										</li>
										<li>
											<CheckIcon className={classes.advantageCheck} /> Keine
											Chatbots oder moderierte Profile
										</li>
									</ul>
									<MuiButton
										variant="outlined"
										color="primary"
                    style={{width: '20em'}}
										onClick={() => router.push("/register?type=0")}
									>
										Als Sugardaddy anmelden
									</MuiButton>
								</Box>
						</Grid>
						<Grid 
							item 
							xs={12} 
							md={6}
							className={classes.gridStyle}
						>
								<Avatar
									src={sugarbabeAdv.src}
									alt="Sugarbabe"
									style={{
										width: "7em",
										height: "7em",
										display: "inline-block",
									}}
								/>
								<Box className={classes.perkList}>
									<h2 className={classes.advantageTitle}>
										Warum Sugarbabes getasugar lieben:
									</h2>
									<ul className={classes.advantageList}>
										<li>
											<CheckIcon className={classes.advantageCheck} /> Täglich
											eine kostenlose Freischaltung<sup style={{lineHeight: 1}}>1</sup>
										</li>
										<li>
											<CheckIcon className={classes.advantageCheck} /> Profile
											und Bilder sehen nur Mitglieder
										</li>
										<li>
											<CheckIcon className={classes.advantageCheck} /> Viele
											geprüfte und verifizierte Sugardaddys
										</li>
										<li>
											<CheckIcon className={classes.advantageCheck} /> Keine
											Chatbots oder moderierte Profile
										</li>
									</ul>
									<MuiButton
										variant="outlined"
										color="primary"
                    style={{width: '20em'}}
										onClick={() => router.push("/register?type=1")}
									>
										Als Sugarbabe anmelden
									</MuiButton>
                  <div style={{fontSize: '0.7em', color: '#bbb'}}><sup>1</sup> durch die Funktion “Catch of the Day”</div>
								</Box>
						</Grid>
					</Grid>
				</div>
  )
}