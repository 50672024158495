import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/components/parallaxStyle.js";

const useStyles = makeStyles(styles);

export default function Parallax(props) {
  let windowScrollTop;
  // if (window.innerWidth >= 768) {
  //   windowScrollTop = window.pageYOffset / 3;
  // } else {
  //   windowScrollTop = 0;
  // }

  const {
    filter,
    className,
    children,
    style,
    image,
    mobileImage,
    small,
    responsive,
    scroll,
    alt,
    basePaddingTop = 0
  } = props;

  const [transform, setTransform] = useState("translate3d(0,0px,0)");
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    if (window.innerWidth >= 768 && !scroll) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768 && !scroll) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });
  const resetTransform = () => {
    var windowScrollTop = window.scrollY / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: filter,
    [classes.small]: small,
    [classes.parallaxResponsive]: responsive,
    [className]: className !== undefined,
  });
  return (
    <div
      title={alt}
      className={parallaxClasses}
      key="parallax"
      style={{
        ...style,
        backgroundImage:
          "url(" + (windowWidth >= 767 ? image : mobileImage) + ")",
        transform: transform,
        marginTop: (basePaddingTop + 4 ) + "em",
      }}
    >
      {children}
    </div>
  );
}
